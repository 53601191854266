import BNB_LOGO_URL from '../assets/images/chains/bsc.svg'
import ARBITRUM_LOGO_URL from '../assets/images/chains/arbitrum-one.svg'
import ETH_LOGO_URL from '../assets/images/chains/eth.svg'
import POLYGON_LOGO_URL from '../assets/images/chains/polygon.svg'
import AVALANCHE_LOGO_URL from '../assets/images/chains/avalanche.svg'
import BASE_LOGO_URL from '../assets/images/chains/base.svg'
import OP_LOGO_URL from '../assets/images/chains/op.svg'
import SCROLL_LOGO_URL from '../assets/images/chains/scroll.svg'

export enum SupportedNetwork {
  BNB = 56,
  ARBITRUM = 42161,
  ETHEREUM = 1,
  POLYGON = 137,
  AVALANCHE = 43114,
  BASE = 8453,
  OPTIMISM = 10,
  SCROLL = 534352,
}

export type CoingeckoPlatformId =
  | 'binance-smart-chain'
  | 'arbitrum-one'
  | 'ethereum'
  | 'polygon-pos'
  | 'avalanche'
  | 'base'
  | 'optimistic-ethereum'
  | 'scroll'

export type NetworkInfo = {
  id: SupportedNetwork
  route: string
  name: string
  imageURL: string
  bgColor: string
  primaryColor: string
  secondaryColor: string
  blurb?: string
  subgraphStartBlock?: number
  coingeckoPlatformId: CoingeckoPlatformId
}

export const BNBNetworkInfo: NetworkInfo = {
  id: SupportedNetwork.BNB,
  route: 'bnb',
  name: 'BNB Chain',
  bgColor: '#F0B90B',
  primaryColor: '#F0B90B',
  secondaryColor: '#F0B90B',
  imageURL: BNB_LOGO_URL,
  blurb: '',
  coingeckoPlatformId: 'binance-smart-chain',
}

export const ArbitrumNetworkInfo: NetworkInfo = {
  id: SupportedNetwork.ARBITRUM,
  route: 'arbitrum',
  name: 'Arbitrum',
  imageURL: ARBITRUM_LOGO_URL,
  bgColor: '#0A294B',
  primaryColor: '#0490ED',
  secondaryColor: '#96BEDC',
  blurb: '',
  coingeckoPlatformId: 'arbitrum-one',
}

export const EthNetworkInfo: NetworkInfo = {
  id: SupportedNetwork.ETHEREUM,
  route: 'ethereum',
  name: 'Ethereum',
  imageURL: ETH_LOGO_URL,
  bgColor: '#0A294B',
  primaryColor: '#0490ED',
  secondaryColor: '#96BEDC',
  blurb: '',
  subgraphStartBlock: 17835031,
  coingeckoPlatformId: 'ethereum',
}

export const PolygonNetworkInfo: NetworkInfo = {
  id: SupportedNetwork.POLYGON,
  route: 'polygon',
  name: 'Polygon',
  imageURL: POLYGON_LOGO_URL,
  bgColor: '#0A294B',
  primaryColor: '#0490ED',
  secondaryColor: '#96BEDC',
  subgraphStartBlock: 17835031,
  coingeckoPlatformId: 'polygon-pos',
}

export const AvalancheNetworkInfo: NetworkInfo = {
  id: SupportedNetwork.AVALANCHE,
  route: 'avalanche',
  name: 'Avalanche',
  imageURL: AVALANCHE_LOGO_URL,
  bgColor: '#0A294B',
  primaryColor: '#0490ED',
  secondaryColor: '#96BEDC',
  subgraphStartBlock: 17835031,
  coingeckoPlatformId: 'avalanche',
}

export const BaseNetworkInfo: NetworkInfo = {
  id: SupportedNetwork.BASE,
  route: 'base',
  name: 'Base',
  imageURL: BASE_LOGO_URL,
  bgColor: '#0A294B',
  primaryColor: '#0490ED',
  secondaryColor: '#96BEDC',
  subgraphStartBlock: 6056813,
  coingeckoPlatformId: 'base',
}

export const OptimismNetworkInfo: NetworkInfo = {
  id: SupportedNetwork.OPTIMISM,
  route: 'optimism',
  name: 'Optimism',
  imageURL: OP_LOGO_URL,
  bgColor: '#0A294B',
  primaryColor: '#0490ED',
  secondaryColor: '#96BEDC',
  blurb: 'Beta',
  subgraphStartBlock: 109877276,
  coingeckoPlatformId: 'optimistic-ethereum',
}

export const ScrollNetworkInfo: NetworkInfo = {
  id: SupportedNetwork.SCROLL,
  route: 'scroll',
  name: 'Scroll',
  imageURL: SCROLL_LOGO_URL,
  bgColor: '#0A294B',
  primaryColor: '#0490ED',
  secondaryColor: '#96BEDC',
  blurb: '',
  subgraphStartBlock: 86186,
  coingeckoPlatformId: 'scroll',
}

export const SUPPORTED_NETWORK_VERSIONS: NetworkInfo[] = [
  BNBNetworkInfo,
  ArbitrumNetworkInfo,
  EthNetworkInfo,
  AvalancheNetworkInfo,
  BaseNetworkInfo,
  ScrollNetworkInfo,
  OptimismNetworkInfo,
  // NOTE: Temporarily disable some chains since we did not launch on them and
  //       have no data
  // PolygonNetworkInfo,
]

export const NETWORK_INFOS: { [id in SupportedNetwork]: NetworkInfo } = {
  [SupportedNetwork.BNB]: BNBNetworkInfo,
  [SupportedNetwork.ARBITRUM]: ArbitrumNetworkInfo,
  [SupportedNetwork.ETHEREUM]: EthNetworkInfo,
  [SupportedNetwork.POLYGON]: PolygonNetworkInfo,
  [SupportedNetwork.AVALANCHE]: AvalancheNetworkInfo,
  [SupportedNetwork.BASE]: BaseNetworkInfo,
  [SupportedNetwork.OPTIMISM]: OptimismNetworkInfo,
  [SupportedNetwork.SCROLL]: ScrollNetworkInfo,
}
