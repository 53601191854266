import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { useDeltaTimestamps } from 'utils/queries'
import { useBlocksFromTimestamps } from 'hooks/useBlocksFromTimestamps'
import { AssetData } from 'state/assets/reducer'
import { ClientType, useDataClient, useBlockClient, useActiveNetworkVersion } from 'state/application/hooks'
import { getPoolLabel } from 'utils'
import { FORMATTED_TOKEN_SYMBOLS, LOWERCASE_HIDED_ASSET_ADDRESS } from '../../constants'
import { NETWORK_INFOS } from 'constants/networks'

export const ASSETS_BULK = (
  block: number | undefined,
  block24: number | undefined,
  block48: number | undefined,
  blockWeek: number | undefined,
  assets: string[]
) => {
  let assetString = `[`
  assets.map((address) => {
    return (assetString += `"${address}",`)
  })
  assetString += ']'
  const queryString =
    `
    query assets {
      assets(where: {id_in: ${assetString}, id_not:"0x3f56e0c36d275367b8c502090edf38289b3dea0d"},` +
    (block ? `block: {number: ${block}} ,` : ``) +
    ` subgraphError: allow) {
        id
        underlyingToken{
          token: id
          symbol
          name
          decimals
        }
        poolAddress
        totalTradeVolumeUSD
        totalCollectedFeeUSD
        totalValueLocked: liabilityUSD
      }
      assets24: assets(where: {id_in: ${assetString}, id_not:"0x3f56e0c36d275367b8c502090edf38289b3dea0d"},` +
    (block24 ? `block: {number: ${block24}} ,` : ``) +
    ` subgraphError: allow) {
        id
        underlyingToken{
          token: id
          symbol
          name
          decimals
        }
        poolAddress
        totalTradeVolumeUSD
        totalCollectedFeeUSD
        totalValueLocked: liabilityUSD
      }
      assets48: assets(where: {id_in: ${assetString}, id_not:"0x3f56e0c36d275367b8c502090edf38289b3dea0d"},` +
    (block48 ? `block: {number: ${block48}} ,` : ``) +
    ` subgraphError: allow) {
        id
        underlyingToken{
          token: id
          symbol
          name
          decimals
        }
        poolAddress
        totalTradeVolumeUSD
        totalCollectedFeeUSD
        totalValueLocked: liabilityUSD
      }
      assetsWeek: assets(where: {id_in: ${assetString}, id_not:"0x3f56e0c36d275367b8c502090edf38289b3dea0d"},` +
    (blockWeek ? `block: {number: ${blockWeek}} ,` : ``) +
    ` subgraphError: allow) {
        id
        underlyingToken{
          token: id
          symbol
          name
          decimals
        }
        poolAddress
        totalTradeVolumeUSD
        totalCollectedFeeUSD
        totalValueLocked: liabilityUSD
      }
    }
    `
  return gql(queryString)
}

export const ASSET_APR_DATA = (block: number | undefined, assets: string[]) => {
  let assetString = `[`
  assets.map((address) => {
    return (assetString += `"${address}",`)
  })
  assetString += ']'
  const queryString =
    `
    query assets {
      assets(where: {id_in: ${assetString}, id_not:"0x3f56e0c36d275367b8c502090edf38289b3dea0d"},` +
    (block ? `block: {number: ${block}} ,` : ``) +
    ` subgraphError: allow) {
        id
        womBaseAPR
        totalBonusTokenAPR
        medianBoostedAPR
      }
    }
    `
  return gql(queryString)
}

interface AssetFields {
  id: string
  underlyingToken: {
    token: string
    symbol: string
    name: string
    decimals: string
  }
  totalTradeVolumeUSD: string
  totalCollectedFeeUSD: string
  totalValueLocked: string
  poolAddress: string
  womBaseAPR: string
  totalBonusTokenAPR: string
  medianBoostedAPR: string
}

interface AssetDataResponse {
  assets: AssetFields[]
  assets24: AssetFields[]
  assets48: AssetFields[]
  assetsWeek: AssetFields[]
}

/**
 * Fetch top addresses by volume
 */
export function useFetchedAssetDatas(assetAddresses: string[]): {
  loading: boolean
  error: boolean
  data:
    | {
        [address: string]: AssetData
      }
    | undefined
} {
  const [activeNetwork] = useActiveNetworkVersion()
  const dataClient = useDataClient()[ClientType.MAIN]
  const blockClient = useBlockClient()
  const startBlockForSubgraph = NETWORK_INFOS[activeNetwork.id].subgraphStartBlock
  // get blocks from historic timestamps
  const [t15min, t24, t48, tWeek] = useDeltaTimestamps()
  const { blocks, error: blockError } = useBlocksFromTimestamps([t15min, t24, t48, tWeek], blockClient)
  const [block15min, block24, block48, blockWeek] = blocks ?? []
  if (startBlockForSubgraph && block15min && block24 && block48 && blockWeek) {
    block15min.number = Math.max(startBlockForSubgraph, block15min.number)
    block24.number = Math.max(startBlockForSubgraph, block24.number)
    block48.number = Math.max(startBlockForSubgraph, block48.number)
    blockWeek.number = Math.max(startBlockForSubgraph, blockWeek.number)
  }
  const { loading, error, data } = useQuery<AssetDataResponse>(
    ASSETS_BULK(block15min?.number, block24?.number, block48?.number, blockWeek?.number, assetAddresses),
    {
      client: dataClient,
    }
  )

  // const { loading: aprLoading, error: aprErorr, data: aprData } = useQuery<AssetDataResponse>(
  //   ASSET_APR_DATA(block15min?.number, assetAddresses),
  //   {
  //     client: womAprClient,
  //   }
  // )

  const anyError = Boolean(error || blockError)
  const anyLoading = Boolean(loading)

  // return early if not all data yet
  if (anyError || anyLoading) {
    return {
      loading: anyLoading,
      error: anyError,
      data: undefined,
    }
  }

  const parsed = data?.assets
    ? data.assets.reduce((accum: { [address: string]: AssetFields }, poolData) => {
        accum[poolData.id] = poolData
        return accum
      }, {})
    : {}
  const parsed24 = data?.assets24
    ? data.assets24.reduce((accum: { [address: string]: AssetFields }, poolData) => {
        accum[poolData.id] = poolData
        return accum
      }, {})
    : {}
  const parsed48 = data?.assets48
    ? data.assets48.reduce((accum: { [address: string]: AssetFields }, poolData) => {
        accum[poolData.id] = poolData
        return accum
      }, {})
    : {}
  const parsedWeek = data?.assetsWeek
    ? data.assetsWeek.reduce((accum: { [address: string]: AssetFields }, poolData) => {
        accum[poolData.id] = poolData
        return accum
      }, {})
    : {}

  // const parsedAPR = aprData?.assets
  //   ? aprData.assets.reduce((accum: { [address: string]: AssetFields }, poolData) => {
  //       accum[poolData.id] = poolData
  //       return accum
  //     }, {})
  //   : {}

  // format data and calculate daily changes
  const formatted = assetAddresses
    .filter((address) => !LOWERCASE_HIDED_ASSET_ADDRESS.includes(address.toLowerCase()))
    .reduce((accum: { [address: string]: AssetData }, address) => {
      const current: AssetFields | undefined = parsed[address]
      const oneDay: AssetFields | undefined = parsed24[address]
      const twoDay: AssetFields | undefined = parsed48[address]
      const week: AssetFields | undefined = parsedWeek[address]
      // const currentApr: AssetFields | undefined = parsedAPR[address]
      if (!current) {
        return accum
      }

      const [volumeUSD, volume24HUSD, volumeLast24HUSD, volume7DUSD] = [
        current ? parseFloat(current.totalTradeVolumeUSD) / 2 : 0,
        oneDay
          ? parseFloat(current.totalTradeVolumeUSD) / 2 - parseFloat(oneDay.totalTradeVolumeUSD) / 2
          : parseFloat(current.totalTradeVolumeUSD) / 2,
        twoDay
          ? parseFloat(oneDay.totalTradeVolumeUSD) / 2 - parseFloat(twoDay.totalTradeVolumeUSD) / 2
          : parseFloat(current.totalTradeVolumeUSD) / 2,
        week
          ? parseFloat(current.totalTradeVolumeUSD) / 2 - parseFloat(week.totalTradeVolumeUSD) / 2
          : parseFloat(current.totalTradeVolumeUSD) / 2,
      ]

      const volumeUSDChange =
        volume24HUSD == volumeLast24HUSD ? 0 : (volume24HUSD - volumeLast24HUSD) / volumeLast24HUSD

      const [fee24HUSD, feeLast24HUSD] =
        current && oneDay && twoDay
          ? [
              parseFloat(current.totalCollectedFeeUSD) - parseFloat(oneDay.totalCollectedFeeUSD),
              parseFloat(oneDay.totalCollectedFeeUSD) - parseFloat(twoDay.totalCollectedFeeUSD),
            ]
          : [0, 0]

      const feeUSDChange = fee24HUSD == feeLast24HUSD ? 0 : (fee24HUSD - feeLast24HUSD) / feeLast24HUSD

      const tvlUSD = current ? +current.totalValueLocked : 0
      const lastDayTvlUSD = oneDay ? +oneDay.totalValueLocked : 0
      const tvlUSDChange = tvlUSD == lastDayTvlUSD ? 0 : (tvlUSD - lastDayTvlUSD) / lastDayTvlUSD
      // APR Data
      // const baseApr = currentApr ? Number(currentApr.womBaseAPR) : 0
      // const medianBoostedApr = currentApr ? Number(currentApr.medianBoostedAPR) : 0
      // const bonusApr = currentApr ? Number(currentApr.totalBonusTokenAPR) : 0

      accum[address] = {
        exists: !!current,
        name: current ? current.underlyingToken.name : '',
        pool: current ? getPoolLabel(current.poolAddress) : '',
        symbol: current ? FORMATTED_TOKEN_SYMBOLS[current.underlyingToken.token] || current.underlyingToken.symbol : '',
        token: current ? current.underlyingToken.token : '',
        address: current ? current.id : '',
        tvlUSD,
        tvlUSDChange,
        volumeUSD,
        volume24HUSD,
        volume7DUSD,
        volumeUSDChange,
        fee24HUSD,
        feeUSDChange,
        baseApr: 0,
        medianBoostedApr: 0,
        bonusApr: 0,
        totalMedianApr: 0,
      }
      return accum
    }, {})
  return {
    loading: anyLoading,
    error: anyError,
    data: formatted,
  }
}
