/* eslint-disable @typescript-eslint/camelcase */
import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { Currency, CurrencyAmount, Fraction, Percent, Token } from '@uniswap/sdk-core'
import IUniswapV2Router02ABI from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { NetworkInfo, SupportedNetwork } from 'constants/networks'
import JSBI from 'jsbi'
import { ROUTER_ADDRESS } from '../constants'
import { TokenAddressMap } from '../state/lists/hooks'

// @TODO: refactor
export enum PoolLabels {
  // BNB
  MAIN = 'MAIN',
  SIDE = 'SIDE',
  BNB = 'BNB',
  qWOM = 'qWOM',
  mWOM = 'mWOM',
  wmxWOM = 'wmxWOM',
  INNOVATION = 'INNOVATION',
  BNBx = 'BNBx',
  stkBNB = 'stkBNB',
  iUSD = 'iUSD',
  CUSD = 'CUSD',
  axlUSDC = 'axlUSDC',
  USDD = 'USDD',
  BOB = 'BOB',
  frxETH = 'frxETH',
  MIM = 'MIM',
  USDPlus = 'USD+',
  ankrBNB = 'ankrBNB',
  BNBy = 'BNBy',
  SMART_HAY = 'Smart HAY',
  WBETH = 'WBETH',
  ankrETH = 'ankrETH',
  SnBNB = 'SnBNB',
  rBNB = 'rBNB',
  USDS = 'USDS',
  CROSS_CHAIN_BNB = 'Cross_Chain_BNB',
  zBNB = 'zBNB',
  zUSD = 'zUSD',
  USDV = 'USDV',
  Volatile_BNB = 'Volatile_BNB',
  Standalone_1_BNB = 'Standalone_1_BNB',
  Standalone_2_BNB = 'Standalone_2_BNB',
  Standalone_3_BNB = 'Standalone_3_BNB',
  LINA_BNB = 'LINA_BNB',
  // Arbitrum
  MAIN_ARB = 'MAIN_ARB',
  USDPlus_ARB = 'Overnight',
  MIM_ARB = 'MIM',
  BOB_ARB = 'BOB',
  mWOM_ARB = 'mWOM',
  qWOM_ARB = 'qWOM',
  wmxWOM_ARB = 'wmxWOM',
  frxETH_ARB = 'frxETH',
  FRAX_ARB = 'FRAX',
  jUSDC_ARB = 'jUSDC',
  ankrETH_ARB = 'ankrETH',
  wstETH_ARB = 'wstETH',
  fUSDC_ARB = 'fUSDC_ARB',
  PENDLE_ARB = 'PENDLE_ARB',
  CROSS_CHAIN_ARB = 'Cross_Chain_ARB',
  USDV_ARB = 'USDV_ARB',
  FRAX_USDV_ARB = 'FRAX_USDV_ARB',
  FRAX_sFRAX_ARB = 'FRAX_sFRAX_ARB',
  Volatile_ARB = 'Volatile_ARB',
  // Ethereum
  FRAX_ETH = 'FRAX_ETH',
  agEUR_ETH = 'agEUR_ETH',
  frxETH_ETH = 'frxETH_ETH',
  wstETH_ETH = 'wstETH_ETH',
  ETHx_ETH = 'ETHx_ETH',
  CROSS_CHAIN_ETH = 'Cross_Chain_ETH',
  USDV_ETH = 'USDV_ETH',
  mWOM_ETH = 'mWOM_ETH',
  wmxWOM_ETH = 'wmxWOM_ETH',
  Volatile_ETH = 'Volatile_ETH',
  // Polygon
  CROSS_CHAIN_POLYGON = 'Cross_Chain_Polygon',
  // Avalanche
  CROSS_CHAIN_AVALANCHE = 'Cross_Chain_Avalanche',
  WAVAX_sAVAX = 'WAVAX_sAVAX',
  USDV_AVAX = 'USDV_AVAX',
  ggAVAX = 'ggAVAX',
  Volatile_AVAX = 'Volatile_AVAX',
  UXDA_AVAX = 'UXDA_AVAX',
  // Base
  CROSS_CHAIN_BASE = 'Cross_Chain_Base',
  USDS_BASE = 'USDS_Base',
  // Optimism
  USDV_OP = 'USDV_OP',
  CROSS_CHAIN_OP = 'Cross_Chain_Op',
  FRAX_OP = 'FRAX_OP',
  DOLA_OP = 'DOLA_OP',
  frxETH_OP = 'frxETH_OP',
  // Scroll
  CROSS_CHAIN_SCROLL = 'Cross_Chain_Scroll',
  Volatile_SCROLL = 'Volatile_SCROLL',
}

export enum IncubationTokenSymbols {
  MGP = 'MGP',
  QUO = 'QUO',
  WMX = 'WMX',
  SABLE = 'SABLE',
}

export interface IncubationToken {
  symbol: IncubationTokenSymbols
  name: string
  address: string
  quantity: number
  coinGeckoId: string
  link?: string
}

export const IncubationTokens: IncubationToken[] = [
  {
    symbol: IncubationTokenSymbols.MGP,
    name: 'Magpie',
    address: '0xd06716e1ff2e492cc5034c2e81805562dd3b45fa',
    quantity: 30000000, // 3% of MGP Supply
    coinGeckoId: 'magpie',
  },
  {
    symbol: IncubationTokenSymbols.QUO,
    name: 'Quoll',
    address: '0x08b450e4a48c04cdf6db2bd4cf24057f7b9563ff',
    quantity: 40000000, // 4% of QUO supply
    coinGeckoId: 'quo',
  },
  {
    symbol: IncubationTokenSymbols.WMX,
    name: 'Wombex',
    address: '0xa75d9ca2a0a1d547409d82e1b06618ec284a2ced',
    quantity: 3000000, // 3% of WMX supply
    coinGeckoId: 'wombex',
  },
  {
    symbol: IncubationTokenSymbols.SABLE,
    name: 'Sable',
    address: '0x1ee098cbaf1f846d5df1993f7e2d10afb35a878d',
    quantity: 3_000_000, // 3% of SABLE supply
    link: 'https://sable.finance/',
    coinGeckoId: 'sable',
  },
]

export type IncubationTokenRevenue<T> = { [tokenAddress in IncubationTokenSymbols]: number }

export const POOL_DISPLAY_NAME: { [poolLabel in string]: string } = {
  // BNB Chain
  [PoolLabels.MAIN]: 'Stable Pool',
  [PoolLabels.SIDE]: 'lisUSD Pool',
  [PoolLabels.BNB]: 'BNB Pool',
  [PoolLabels.qWOM]: 'qWOM Pool',
  [PoolLabels.mWOM]: 'mWOM Pool',
  [PoolLabels.wmxWOM]: 'wmxWOM Pool',
  [PoolLabels.INNOVATION]: 'FRAX Pool',
  [PoolLabels.BNBx]: 'BNBx Pool',
  [PoolLabels.stkBNB]: 'stkBNB Pool',
  [PoolLabels.iUSD]: 'iUSD Pool',
  [PoolLabels.CUSD]: 'CUSD Pool',
  [PoolLabels.axlUSDC]: 'axlUSDC Pool',
  [PoolLabels.USDD]: 'USDD Pool',
  [PoolLabels.BOB]: 'BOB Pool',
  [PoolLabels.frxETH]: 'frxETH Pool',
  [PoolLabels.MIM]: 'MIM Pool',
  [PoolLabels.USDPlus]: 'Stable Guild Pool',
  [PoolLabels.ankrBNB]: 'ankrBNB Pool',
  [PoolLabels.BNBy]: 'BNBy Pool',
  [PoolLabels.SMART_HAY]: 'lisUSD Pool',
  [PoolLabels.WBETH]: 'wBETH Pool',
  [PoolLabels.ankrETH]: 'ankrETH Pool',
  [PoolLabels.rBNB]: 'rBNB Pool',
  [PoolLabels.USDS]: 'USDS Pool',
  [PoolLabels.CROSS_CHAIN_BNB]: 'Cross Chain Pool',
  [PoolLabels.zBNB]: 'zBNB Pool',
  [PoolLabels.zUSD]: 'zUSD Pool',
  [PoolLabels.USDV]: 'USDV Pool',
  [PoolLabels.Volatile_BNB]: 'Main Pool',
  [PoolLabels.Standalone_1_BNB]: 'Standalone Pool 1',
  [PoolLabels.Standalone_2_BNB]: 'Standalone Pool 2',
  [PoolLabels.Standalone_3_BNB]: 'Standalone Pool 3',
  [PoolLabels.LINA_BNB]: 'LINA Pool',
  // Arbitrum
  [PoolLabels.MAIN_ARB]: 'Main Pool',
  [PoolLabels.USDPlus_ARB]: 'Overnight Pool',
  [PoolLabels.MIM_ARB]: 'MIM Pool',
  [PoolLabels.BOB_ARB]: 'BOB Pool',
  [PoolLabels.mWOM_ARB]: 'mWOM Pool',
  [PoolLabels.qWOM_ARB]: 'qWOM Pool',
  [PoolLabels.wmxWOM_ARB]: 'wmxWOM Pool',
  [PoolLabels.frxETH_ARB]: 'frxETH Pool',
  [PoolLabels.FRAX_ARB]: 'FRAX Pool',
  [PoolLabels.jUSDC_ARB]: 'jUSDC Pool',
  [PoolLabels.ankrETH_ARB]: 'ankrETH Pool',
  [PoolLabels.wstETH_ARB]: 'wstETH Pool',
  [PoolLabels.fUSDC_ARB]: 'fUSDC Pool',
  [PoolLabels.PENDLE_ARB]: 'Pendle Pool',
  [PoolLabels.SnBNB]: 'SnBNB Pool',
  [PoolLabels.CROSS_CHAIN_ARB]: 'Cross Chain Pool',
  [PoolLabels.USDV_ARB]: 'USDV Pool',
  [PoolLabels.FRAX_USDV_ARB]: 'FRAX-USDV Pool',
  [PoolLabels.FRAX_sFRAX_ARB]: 'FRAX-sFRAX-USDC Pool',
  [PoolLabels.Volatile_ARB]: 'Volatile Pool',
  // Ethereum
  [PoolLabels.FRAX_ETH]: 'FRAX Pool',
  [PoolLabels.agEUR_ETH]: 'agEUR Pool',
  [PoolLabels.frxETH_ETH]: 'frxETH Pool',
  [PoolLabels.wstETH_ETH]: 'wstETH Pool',
  [PoolLabels.ETHx_ETH]: 'ETHx Pool',
  [PoolLabels.CROSS_CHAIN_ETH]: 'Cross Chain Pool',
  [PoolLabels.USDV_ETH]: 'USDV Pool',
  [PoolLabels.wmxWOM_ETH]: 'wmxWOM Pool',
  [PoolLabels.mWOM_ETH]: 'mWOM Pool',
  [PoolLabels.Volatile_ETH]: 'Volatile Pool',
  // Polygon
  [PoolLabels.CROSS_CHAIN_POLYGON]: 'Cross Chain Pool',
  // Avalanche
  [PoolLabels.CROSS_CHAIN_AVALANCHE]: 'Cross Chain Pool',
  [PoolLabels.WAVAX_sAVAX]: 'sAVAX Pool',
  [PoolLabels.USDV_AVAX]: 'USDV Pool',
  [PoolLabels.ggAVAX]: 'ggAVAX Pool',
  [PoolLabels.Volatile_AVAX]: 'Volatile Pool',
  [PoolLabels.UXDA_AVAX]: 'UXDA Pool',
  // Base
  [PoolLabels.CROSS_CHAIN_BASE]: 'Cross Chain Pool',
  [PoolLabels.USDS_BASE]: 'USDS Pool',
  // Optimism
  [PoolLabels.USDV_OP]: 'USDV Pool',
  [PoolLabels.CROSS_CHAIN_OP]: 'Cross-Chain Pool',
  [PoolLabels.FRAX_OP]: 'FRAX Pool',
  [PoolLabels.DOLA_OP]: 'DOLA Pool',
  [PoolLabels.frxETH_OP]: 'frxETH Pool',
  // Scroll
  [PoolLabels.CROSS_CHAIN_SCROLL]: 'Cross-Chain Pool',
  [PoolLabels.Volatile_SCROLL]: 'Volatile Pool',
}

export const DEFAULT_SWAP_TOKEN: { [poolLabel in string]: string[] } = {
  // BNB Chain
  [PoolLabels.MAIN]: ['BUSD', 'USDC'],
  [PoolLabels.SIDE]: ['BUSD', 'HAY'],
  [PoolLabels.BNB]: ['WBNB', 'BNBx'],
  [PoolLabels.qWOM]: ['WOM', 'qWOM'],
  [PoolLabels.mWOM]: ['WOM', 'mWOM'],
  [PoolLabels.wmxWOM]: ['WOM', 'wmxWOM'],
  [PoolLabels.INNOVATION]: ['BUSD', 'TUSD'],
  [PoolLabels.BNBx]: ['WBNB', 'BNBx'],
  [PoolLabels.stkBNB]: ['WBNB', 'stkBNB'],
  [PoolLabels.iUSD]: ['iUSD', 'BUSD'],
  [PoolLabels.CUSD]: ['CUSD', 'HAY'],
  [PoolLabels.axlUSDC]: ['axlUSDC', 'BUSD'],
  [PoolLabels.USDD]: ['USDD', 'USDC'],
  [PoolLabels.BOB]: ['BOB', 'USDC'],
  [PoolLabels.frxETH]: ['frxETH', 'ETH'],
  [PoolLabels.MIM]: ['MIM', 'USDT'],
  [PoolLabels.USDPlus]: ['USDplus', 'USDC'],
  [PoolLabels.ankrBNB]: ['ankrBNB', 'WBNB'],
  [PoolLabels.BNBy]: ['BNBy', 'WBNB'],
  [PoolLabels.SMART_HAY]: ['HAY', 'USDC'],
  [PoolLabels.WBETH]: ['wBETH', 'ETH'],
  [PoolLabels.ankrETH]: ['ankrETH', 'ETH'],
  [PoolLabels.USDS]: ['USDS', 'USDT'],
  [PoolLabels.CROSS_CHAIN_BNB]: ['USDC', 'USDT'],
  [PoolLabels.zBNB]: ['WBNB', 'zBNB'],
  [PoolLabels.zUSD]: ['USDC', 'zUSD'],
  [PoolLabels.USDV]: ['USDV', 'USDT'],
  [PoolLabels.Volatile_BNB]: ['BTCB', 'USDT'],
  [PoolLabels.Standalone_1_BNB]: ['USDC', 'USDT'],
  [PoolLabels.Standalone_2_BNB]: ['USDC', 'USDT'],
  [PoolLabels.Standalone_3_BNB]: ['USDC', 'USDT'],
  [PoolLabels.LINA_BNB]: ['USDT', 'lUSD'],
  // Arbitrum
  [PoolLabels.MAIN_ARB]: ['USDT', 'USDC'],
  [PoolLabels.USDPlus_ARB]: ['USDplus', 'USDC'],
  [PoolLabels.MIM_ARB]: ['MIM', 'USDT'],
  [PoolLabels.BOB_ARB]: ['BOB', 'USDC'],
  [PoolLabels.mWOM_ARB]: ['mWOM', 'WOM'],
  [PoolLabels.qWOM_ARB]: ['qWOM', 'WOM'],
  [PoolLabels.wmxWOM_ARB]: ['wmxWOM', 'WOM'],
  [PoolLabels.frxETH_ARB]: ['frxETH', 'ETH'],
  [PoolLabels.FRAX_ARB]: ['MAI', 'FRAX'],
  [PoolLabels.jUSDC_ARB]: ['jUSDC', 'USDC'],
  [PoolLabels.ankrETH_ARB]: ['ankrETH', 'ETH'],
  [PoolLabels.wstETH_ARB]: ['wstETH', 'ETH'],
  [PoolLabels.fUSDC_ARB]: ['fUSDC', 'USDC'],
  [PoolLabels.PENDLE_ARB]: ['PENDLE', 'mPendle'],
  [PoolLabels.SnBNB]: ['SnBNB', 'WBNB'],
  [PoolLabels.rBNB]: ['WBNB', 'rBNB'],
  [PoolLabels.CROSS_CHAIN_ARB]: ['USDC', 'USDT'],
  [PoolLabels.USDV_ARB]: ['USDV', 'USDT'],
  [PoolLabels.FRAX_USDV_ARB]: ['FRAX', 'USDV'],
  [PoolLabels.FRAX_sFRAX_ARB]: ['FRAX', 'sFRAX'],
  [PoolLabels.Volatile_ARB]: ['WBTC', 'USDT'],
  // Ethereum
  [PoolLabels.FRAX_ETH]: ['FRAX', 'USDT'],
  [PoolLabels.agEUR_ETH]: ['agEUR', 'EURe'],
  [PoolLabels.frxETH_ETH]: ['frxETH', 'WETH'],
  [PoolLabels.wstETH_ETH]: ['wstETH', 'WETH'],
  [PoolLabels.ETHx_ETH]: ['ETHx', 'WETH'],
  [PoolLabels.CROSS_CHAIN_ETH]: ['USDC', 'USDT'],
  [PoolLabels.USDV_ETH]: ['USDV', 'USDT'],
  [PoolLabels.mWOM_ETH]: ['mWOM', 'WOM'],
  [PoolLabels.wmxWOM_ETH]: ['wmxWOM', 'WOM'],
  [PoolLabels.Volatile_ETH]: ['WBTC', 'USDT'],
  // Polygon
  [PoolLabels.CROSS_CHAIN_POLYGON]: ['USDC', 'USDT'],
  // Avalanche
  [PoolLabels.CROSS_CHAIN_AVALANCHE]: ['USDC', 'USDT'],
  [PoolLabels.WAVAX_sAVAX]: ['WAVAX', 'sAVAX'],
  [PoolLabels.USDV_AVAX]: ['USDV', 'USDT'],
  [PoolLabels.ggAVAX]: ['ggAVAX', 'WAVAX'],
  [PoolLabels.Volatile_AVAX]: ['BTCB', 'USDT'],
  [PoolLabels.UXDA_AVAX]: ['USDC', 'UXDA'],
  // Base
  [PoolLabels.CROSS_CHAIN_BASE]: ['USDC', 'USDbC'],
  [PoolLabels.USDS_BASE]: ['USDS', 'USDbC'],
  // Optimism
  [PoolLabels.USDV_OP]: ['USDV', 'USDT'],
  [PoolLabels.CROSS_CHAIN_OP]: ['USDT', 'USDC'],
  [PoolLabels.FRAX_OP]: ['FRAX', 'USDC'],
  [PoolLabels.DOLA_OP]: ['USDCe', 'DOLA'],
  [PoolLabels.frxETH_OP]: ['frxETH', 'WETH'],
  // Scroll
  [PoolLabels.CROSS_CHAIN_SCROLL]: ['USDC', 'USDT'],
  [PoolLabels.Volatile_SCROLL]: ['WBTC', 'USDC'],
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getBscScanLink(data: string, type: 'transaction' | 'token' | 'address' | 'block'): string {
  if (data) {
    const prefix = 'https://bscscan.com'
    const stripped = data.split('-')[0]
    switch (type) {
      case 'transaction': {
        return `${prefix}/tx/${stripped}`
      }
      case 'token': {
        return `${prefix}/token/${stripped}`
      }
      case 'block': {
        return `${prefix}/block/${stripped}`
      }
      case 'address':
      default: {
        return `${prefix}/address/${stripped}`
      }
    }
  }
  return ''
}

export function getDappLink(poolLabel: string, tokenSymbol: string, type: 'SWAP' | 'DEPOSIT'): string {
  const prefix = 'https://app.wombat.exchange'
  if (tokenSymbol) {
    if (tokenSymbol === 'wmxWom') tokenSymbol = 'wmxWOM' // Temp fix for 'wmxWOM' token

    if (type === 'SWAP') {
      if (poolLabel === 'Unknown') return prefix
      const fromToken =
        tokenSymbol === DEFAULT_SWAP_TOKEN[poolLabel][0]
          ? DEFAULT_SWAP_TOKEN[poolLabel][1]
          : DEFAULT_SWAP_TOKEN[poolLabel][0]
      const path = `/swap?from=${fromToken}&to=${tokenSymbol}`
      return `${prefix}${path}`
    } else if (type === 'DEPOSIT') {
      const path = `/pool?pool=${poolLabel}&token=${tokenSymbol}&action=${type}`
      return `${prefix}${path}`
    } else {
      return prefix
    }
  }
  return prefix
}

export function getPoolLabel(address: string): string {
  switch (address) {
    // BNB Chain
    case '0x312bc7eaaf93f1c60dc5afc115fccde161055fb0':
      return PoolLabels.MAIN
    case '0x0029b7e8e9ed8001c868aa09c74a1ac6269d4183':
      return PoolLabels.BNB
    case '0x0520451b19ad0bb00ed35ef391086a692cfc74b2':
      return PoolLabels.SIDE
    case '0xeeb5a751e0f5231fc21c7415c4a4c6764f67ce2e':
      return PoolLabels.wmxWOM
    case '0x083640c5dbd5a8ddc30100fb09b45901e12f9f55':
      return PoolLabels.mWOM
    case '0x2c5464b9052319e3d76f8279031f04e4b7fd7955':
      return PoolLabels.qWOM
    case '0x48f6a8a0158031baf8ce3e45344518f1e69f2a14':
      return PoolLabels.INNOVATION
    case '0x8df1126de13bcfef999556899f469d64021adbae':
      return PoolLabels.BNBx
    case '0xb0219a90ef6a24a237bc038f7b7a6eac5e01edb0':
      return PoolLabels.stkBNB
    case '0x277e777f7687239b092c8845d4d2cd083a33c903':
      return PoolLabels.iUSD
    case '0x4dfa92842d05a790252a7f374323b9c86d7b7e12':
      return PoolLabels.CUSD
    case '0x8ad47d7ab304272322513ee63665906b64a49da2':
      return PoolLabels.axlUSDC
    case '0x05f727876d7c123b9bb41507251e2afd81ead09a':
      return PoolLabels.USDD
    case '0xea6cdd9e8819bbf7f8791e7d084d9f0a6afa7892':
      return PoolLabels.BOB
    case '0x2ea772346486972e7690219c190dadda40ac5da4':
      return PoolLabels.frxETH
    case '0xb8b1b72a9b9ba90e2539348fec1ad6b265f9f684':
      return PoolLabels.MIM
    case '0x9498563e47d7cfdfa22b818bb8112781036c201c':
      return PoolLabels.USDPlus
    case '0x6f1c689235580341562cdc3304e923cc8fad5bfa':
      return PoolLabels.ankrBNB
    case '0xbed9b758a681d73a95ab4c01309c63aa16297b80':
      return PoolLabels.BNBy
    case '0xa61dccc6c6e34c8fbf14527386ca35589e9b8c27':
      return PoolLabels.SMART_HAY
    case '0x1b507b97c89ede3e40d1b2ed92972197c6276d35':
      return PoolLabels.ankrETH
    case '0x8b892b6ea1d0e5b29b719d6bd6eb9354f1cde060':
      return PoolLabels.WBETH
    case '0x1ee15673e07105bcf360139fa8cafebdd7754bef':
      return PoolLabels.CROSS_CHAIN_BNB
    case '0x9a39f4ab3f52026432835dee6d3db721d95f3d28':
      return PoolLabels.zBNB
    case '0xc26b7cbe7e695a0d11a8cb96140d1cd502945a2c':
      return PoolLabels.zUSD
    case '0xc897a2ae2e45f0d7ba8cbe397208c3e9f8914a9f':
      return PoolLabels.USDV
    case '0x5b573f2e034e37cc883f2a614bddc77b07081b6f':
      return PoolLabels.Volatile_BNB
    case '0x6569ddc1cc2648c89bc8025046a7dd65eb8940f3':
      return PoolLabels.Standalone_1_BNB
    case '0xfcd11c01c14e4c12c3f9835cd5192fe774038d46':
      return PoolLabels.Standalone_2_BNB
    case '0xaded24b510a137b05a8ed958a029dacd6a59efdc':
      return PoolLabels.Standalone_3_BNB
    case '0x84a14a10e7258c68413168c98e905483f9183d7a':
      return PoolLabels.LINA_BNB
    // Arbitrum
    case '0xc6bc781e20f9323012f6e422bdf552ff06ba6cd1':
      return PoolLabels.MAIN_ARB
    case '0xcf20fda54e37f3fb456930f02fb07fccf49e4849':
      return PoolLabels.USDPlus_ARB
    case '0x29eeb257a2a6ecde2984acedf80a1b687f18ec91':
      return PoolLabels.MIM_ARB
    case '0x917caf2b4d6040a9d67a5f8cefc4f89d1b214c1a':
      return PoolLabels.BOB_ARB
    case '0x90ecddec4e4116e30769a4e1ea52c319aca338b6':
      return PoolLabels.mWOM_ARB
    case '0xee9b42b40852a53c7361f527e638b485d49750cd':
      return PoolLabels.wmxWOM_ARB
    case '0x12fa5ab079cff564d599466d39715d35d90af978':
      return PoolLabels.qWOM_ARB
    case '0x20d7ee728900848752fa280fad51af40c47302f1':
      return PoolLabels.frxETH_ARB
    case '0x4a8686df475d4c44324210ffa3fc1dea705296e0':
      return PoolLabels.FRAX_ARB
    case '0xc7a6ba5f28993badb566007bd2e0cb253c431974':
      return PoolLabels.jUSDC_ARB
    case '0xb9bdfe449da096256fe7954ef61a18ee195db77b':
      return PoolLabels.ankrETH_ARB
    case '0xe14302040c0a1eb6fb5a4a79efa46d60029358d9':
      return PoolLabels.wstETH_ARB
    case '0x956454c7be9318863297309183c79b793d370401':
      return PoolLabels.fUSDC_ARB
    case '0xe7159f15e7b1d6045506b228a1ed2136dcc56f48':
      return PoolLabels.PENDLE_ARB
    case '0xf1e604e9a31c3b575f91cf008445b7ce06bf3fef':
      return PoolLabels.SnBNB
    case '0x0592083b285aa75b9c8bad2485c6cccf93ccc348':
      return PoolLabels.rBNB
    case '0x0c735f84bd7eda8f8176236091af8068bb6c41de':
      return PoolLabels.USDS
    case '0xe78876c360716f2225f55a6726b32324fe1b1145':
      return PoolLabels.CROSS_CHAIN_ARB
    case '0xa6ef6c45ebfdbc13f6d032fbdfec9b389c1603e5':
      return PoolLabels.USDV_ARB
    case '0x3cc8c886575968642cab9f430261c81c5b044d4b':
      return PoolLabels.FRAX_USDV_ARB
    case '0xabf19eadb08693278fdbad35cb4e3c1d6484c8bb':
      return PoolLabels.FRAX_sFRAX_ARB
    case '0x39a2f59875bc636b7efecac30b6e97066a850b1e':
      return PoolLabels.Volatile_ARB
    // Ethereum
    case '0x9c02eaf31efe3fee36ebe5aebca12ca979df25cc':
      return PoolLabels.FRAX_ETH
    case '0x0020a8890e723cd94660a5404c4bccbb91680db6':
      return PoolLabels.agEUR_ETH
    case '0x3161f40ea6c0c4cc8b2433d6d530ef255816e854':
      return PoolLabels.frxETH_ETH
    case '0xf8e32ca46ac28799c8fb7dce1ac11a4541160734':
      return PoolLabels.wstETH_ETH
    case '0x647cc8816c2d60a5ff4d1ffef27a5b3637d5ac81':
      return PoolLabels.ETHx_ETH
    case '0xa45c0abeef67c363364e0e73832df9986aba3800':
      return PoolLabels.CROSS_CHAIN_ETH
    case '0x05a33c0eaf81367ce953d2dcd4ea1be8758f4d32':
      return PoolLabels.USDV_ETH
    case '0xcf2e56e086fcd21eab3614a5a78c8ae27c2f0536':
      return PoolLabels.mWOM_ETH
    case '0xe43c1695df76cca4d6079061924d7150fd553c21':
      return PoolLabels.wmxWOM_ETH
    // case '0x89b88a45e23978b38a14695b63f475d4e4ccaf95':
    //   return PoolLabels.Volatile_ETH
    // Polygon
    // /**
    //  * We do not enable `CROSS_CHAIN_POLYGON` since the contract address
    //  * is the same as `CROSS_CHAIN_AVALANCHE`. To resolve this completely
    //  * means we need to determine the picked chain along with the contract
    //  * address, which is quite complex but is not really needed for now.
    //  */
    // case '0xc9bfc3efefe4cf96877009f75a61f5c1937e5d1a':
    //   return PoolLabels.CROSS_CHAIN_POLYGON
    // Avalanche
    case '0xc9bfc3efefe4cf96877009f75a61f5c1937e5d1a':
      return PoolLabels.CROSS_CHAIN_AVALANCHE
    case '0xe3abc29b035874a9f6dcdb06f8f20d9975069d87':
      return PoolLabels.WAVAX_sAVAX
    case '0x108c990c93fa8e3cd88ddb13594d39f09d9b3c02':
      return PoolLabels.USDV_AVAX
    case '0xbba43749efc1bc29ea434d88ebaf8a97dc7aeb77':
      return PoolLabels.ggAVAX
    case '0x89b88a45e23978b38a14695b63f475d4e4ccaf95':
      return PoolLabels.Volatile_AVAX
    case '0x74163b79733aea2d9c4ced777dc49d591db739e9':
      return PoolLabels.UXDA_AVAX
    // Base
    case '0xC9bFC3eFeFe4CF96877009F75a61F5c1937e5d1a':
      return PoolLabels.CROSS_CHAIN_BASE
    case '0x20d7b9ed2c4e2dcc55f9b463975b21bbf2a6ecd1':
      return PoolLabels.USDS_BASE
    // Optimism
    case '0x1a023186ef2994249120cc7faedbea07f40c6fbd':
      return PoolLabels.USDV_OP
    case '0x50a39b94b1dc8472faa08c36a3ef5b0a01c5bd10':
      return PoolLabels.CROSS_CHAIN_OP
    case '0x6bb82a9b0b9b9716b885baeefdbe47b685a0f919':
      return PoolLabels.FRAX_OP
    case '0x489818f2eeaef737105887710f7c5b9323ad3d01':
      return PoolLabels.DOLA_OP
    case '0xb86ba65b75d34402bf377cf83b184554a18fcafa':
      return PoolLabels.frxETH_OP
    // Scroll
    case '0x80f088ae72db6d1ac337340cd6aa0eb1f67337ce':
      return PoolLabels.CROSS_CHAIN_SCROLL
    case '0x15dcc2da1a73194c9c5bb83ecda86251f0b1a17f':
      return PoolLabels.Volatile_SCROLL
    default:
      return 'Unknown'
  }
}

export function getEtherscanLink(
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block',
  networkVersion: NetworkInfo
): string {
  let baseUrl = ''
  switch (networkVersion.id) {
    case SupportedNetwork.BNB:
      baseUrl = 'https://bscscan.com'
      break
    case SupportedNetwork.ARBITRUM:
      baseUrl = 'https://arbiscan.io'
      break
    case SupportedNetwork.ETHEREUM:
      baseUrl = 'https://etherscan.io'
      break
    case SupportedNetwork.OPTIMISM:
      baseUrl = 'https://optimistic.etherscan.io'
      break
    case SupportedNetwork.POLYGON:
      baseUrl = 'https://polygonscan.com'
      break
    case SupportedNetwork.BASE:
      baseUrl = 'https://basescan.org'
      break
    case SupportedNetwork.AVALANCHE:
      baseUrl = 'https://snowtrace.dev'
      break
    case SupportedNetwork.SCROLL:
      baseUrl = 'https://scrollscan.com'
      break
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = networkVersion.id
      return ''
  }
  data = data.split('-')[0]

  switch (type) {
    case 'transaction':
      return `${baseUrl}/tx/${data}`
    case 'token':
      return `${baseUrl}/token/${data}`
    case 'block':
      return `${baseUrl}/block/${data}`
    case 'address':
      return `${baseUrl}/address/${data}`
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = type
      return ''
  }
}

export const currentTimestamp = () => new Date().getTime()

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, startChars = 4, endChars = 4): string {
  if (!address) {
    return ''
  }
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, startChars + 2)}...${parsed.substring(42 - endChars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

const ONE = new Fraction(1, 1)
export function calculateSlippageAmount(value: CurrencyAmount<Currency>, slippage: Percent): [JSBI, JSBI] {
  if (slippage.lessThan(0) || slippage.greaterThan(ONE)) throw new Error('Unexpected slippage')
  return [value.multiply(ONE.subtract(slippage)).quotient, value.multiply(ONE.add(slippage)).quotient]
}
// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS, IUniswapV2Router02ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(tokenAddressMap: TokenAddressMap, token?: Token): boolean {
  return Boolean(token?.isToken && tokenAddressMap[token.chainId]?.[token.address])
}

export function feeTierPercent(fee: number): string {
  return (fee / 10000).toPrecision(1) + '%'
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export const generateColors = () => {
  const currentColorHsl = {
    hue: 0,
    saturation: 40,
    lightness: 50,
  }

  return () => {
    const { hue, saturation, lightness } = currentColorHsl
    const color = `hsl(${hue},${saturation}%,${lightness}%)`
    currentColorHsl.hue += 30
    // 0 and 360 are the same color.
    if (currentColorHsl.hue > 330) {
      currentColorHsl.hue = 0
      currentColorHsl.saturation += 10
    }
    return color
  }
}
