import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import {
  arbitrumBlockClient,
  arbitrumClient,
  avalancheBlockClient,
  avalancheClient,
  bnbBlockClient,
  bnbClient,
  bribeClient,
  ethereumBlockClient,
  ethereumClient,
  polygonBlockClient,
  polygonClient,
  baseBlockClient,
  baseClient,
  optimismBlockClient,
  optimismClient,
  scrollBlockClient,
  scrollClient,
  womCirculatingSupplyClient,
  womTokenClient,
} from 'apollo/client'
import { NetworkInfo, SupportedNetwork } from 'constants/networks'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useActiveWeb3React } from '../../hooks'
import { AppDispatch, AppState } from '../index'
import {
  addPopup,
  ApplicationModal,
  PopupContent,
  removePopup,
  setOpenModal,
  updateActiveNetworkVersion,
  updateSubgraphStatus,
} from './actions'

export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React()

  return useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1])
}

export function useModalOpen(modal: ApplicationModal): boolean {
  const openModal = useSelector((state: AppState) => state.application.openModal)
  return openModal === modal
}

export function useToggleModal(modal: ApplicationModal): () => void {
  const open = useModalOpen(modal)
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(open ? null : modal)), [dispatch, modal, open])
}

export function useOpenModal(modal: ApplicationModal): () => void {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal])
}

export function useCloseModals(): () => void {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(null)), [dispatch])
}

export function useWalletModalToggle(): () => void {
  return useToggleModal(ApplicationModal.WALLET)
}

export function useToggleSettingsMenu(): () => void {
  return useToggleModal(ApplicationModal.SETTINGS)
}

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useDispatch()

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }))
    },
    [dispatch]
  )
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useDispatch()
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }))
    },
    [dispatch]
  )
}

// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useSelector((state: AppState) => state.application.popupList)
  return useMemo(() => list.filter((item) => item.show), [list])
}

// returns a function that allows adding a popup
export function useSubgraphStatus(): [
  {
    available: boolean | null
    syncedBlock: number | undefined
    headBlock: number | undefined
  },
  (available: boolean | null, syncedBlock: number | undefined, headBlock: number | undefined) => void
] {
  const dispatch = useDispatch()
  const status = useSelector((state: AppState) => state.application.subgraphStatus)

  const update = useCallback(
    (available: boolean | null, syncedBlock: number | undefined, headBlock: number | undefined) => {
      dispatch(updateSubgraphStatus({ available, syncedBlock, headBlock }))
    },
    [dispatch]
  )
  return [status, update]
}

// returns a function that allows adding a popup
export function useActiveNetworkVersion(): [NetworkInfo, (activeNetworkVersion: NetworkInfo) => void] {
  const dispatch = useDispatch()
  const activeNetwork = useSelector((state: AppState) => state.application.activeNetworkVersion)
  const update = useCallback(
    (activeNetworkVersion: NetworkInfo) => {
      dispatch(updateActiveNetworkVersion({ activeNetworkVersion }))
    },
    [dispatch]
  )
  return [activeNetwork, update]
}

export function useBNBClient(): ApolloClient<NormalizedCacheObject> {
  return bnbClient
}

export function useBNBBlockClient(): ApolloClient<NormalizedCacheObject> {
  return bnbBlockClient
}

export function useWomTokenClient(): ApolloClient<NormalizedCacheObject> {
  const [activeNetwork] = useActiveNetworkVersion()
  switch (activeNetwork.id) {
    case SupportedNetwork.BNB:
      return bnbClient
    case SupportedNetwork.ARBITRUM:
      return arbitrumClient
    case SupportedNetwork.ETHEREUM:
      return ethereumClient
    case SupportedNetwork.POLYGON:
      return polygonClient
    case SupportedNetwork.AVALANCHE:
      return avalancheClient
    case SupportedNetwork.BASE:
      return baseClient
    case SupportedNetwork.OPTIMISM:
      return optimismClient
    case SupportedNetwork.SCROLL:
      return scrollClient
    default:
      const exhautiveCheck: never = activeNetwork.id
      return womTokenClient
  }
}

export function useWomCirculatingSupplyClient(): ApolloClient<NormalizedCacheObject> {
  return womCirculatingSupplyClient
}

export function useBribeClient(): ApolloClient<NormalizedCacheObject> {
  return bribeClient
}

// @TODO: Temp fix for readablity. Will remove after new version subgraph on BNB synced
export enum ClientType {
  MAIN,
  BRIBE,
  TOKEN,
  APR,
}

type TClient = {
  [s: string]: ApolloClient<NormalizedCacheObject>
}

export const BNBClients: TClient = {
  [ClientType.MAIN]: bnbClient,
}

export const ArbitrumClients: TClient = {
  [ClientType.MAIN]: arbitrumClient,
}

export const EthereumClients: TClient = {
  [ClientType.MAIN]: ethereumClient,
}

export const PolygonClients: TClient = {
  [ClientType.MAIN]: polygonClient,
}

export const AvalancheClients: TClient = {
  [ClientType.MAIN]: avalancheClient,
}

export const BaseClients: TClient = {
  [ClientType.MAIN]: baseClient,
}

export const OptimismClients: TClient = {
  [ClientType.MAIN]: optimismClient,
}

export const ScrollClients: TClient = {
  [ClientType.MAIN]: scrollClient,
}

export function useBlockClient(): ApolloClient<NormalizedCacheObject> {
  const [activeNetwork] = useActiveNetworkVersion()
  switch (activeNetwork.id) {
    case SupportedNetwork.BNB:
      return bnbBlockClient
    case SupportedNetwork.ARBITRUM:
      return arbitrumBlockClient
    case SupportedNetwork.ETHEREUM:
      return ethereumBlockClient
    case SupportedNetwork.POLYGON:
      return polygonBlockClient
    case SupportedNetwork.AVALANCHE:
      return avalancheBlockClient
    case SupportedNetwork.BASE:
      return baseBlockClient
    case SupportedNetwork.OPTIMISM:
      return optimismBlockClient
    case SupportedNetwork.SCROLL:
      return scrollBlockClient
    default:
      const exhaustiveCheck: never = activeNetwork.id
      return bnbBlockClient
  }
}

export function useDataClient(): TClient {
  const [activeNetwork] = useActiveNetworkVersion()
  switch (activeNetwork.id) {
    case SupportedNetwork.BNB:
      return BNBClients
    case SupportedNetwork.ARBITRUM:
      return ArbitrumClients
    case SupportedNetwork.ETHEREUM:
      return EthereumClients
    case SupportedNetwork.POLYGON:
      return PolygonClients
    case SupportedNetwork.AVALANCHE:
      return AvalancheClients
    case SupportedNetwork.BASE:
      return BaseClients
    case SupportedNetwork.OPTIMISM:
      return OptimismClients
    case SupportedNetwork.SCROLL:
      return ScrollClients
    default:
      const exhaustiveCheck: never = activeNetwork.id
      return BNBClients
  }
}

// Get all required subgraph clients
export function useClients(): {
  dataClient: ApolloClient<NormalizedCacheObject>
  blockClient: ApolloClient<NormalizedCacheObject>
} {
  const [activeNetwork] = useActiveNetworkVersion()
  switch (activeNetwork.id) {
    case SupportedNetwork.BNB:
      return {
        dataClient: bnbClient,
        blockClient: bnbBlockClient,
      }
    case SupportedNetwork.ARBITRUM:
      return {
        dataClient: arbitrumClient,
        blockClient: arbitrumBlockClient,
      }
    case SupportedNetwork.ETHEREUM:
      return {
        dataClient: ethereumClient,
        blockClient: ethereumBlockClient,
      }
    case SupportedNetwork.POLYGON:
      return {
        dataClient: polygonClient,
        blockClient: polygonBlockClient,
      }
    case SupportedNetwork.AVALANCHE:
      return {
        dataClient: avalancheClient,
        blockClient: avalancheBlockClient,
      }
    case SupportedNetwork.BASE:
      return {
        dataClient: baseClient,
        blockClient: baseBlockClient,
      }
    case SupportedNetwork.OPTIMISM:
      return {
        dataClient: optimismClient,
        blockClient: optimismBlockClient,
      }
    case SupportedNetwork.SCROLL:
      return {
        dataClient: optimismClient,
        blockClient: optimismBlockClient,
      }
    default:
      const exhaustiveCheck: never = activeNetwork.id
      return {
        dataClient: bnbClient,
        blockClient: bnbBlockClient,
      }
  }
}
