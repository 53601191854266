import { currentTimestamp } from './../../utils/index'
import { updateVeWomData, updateVeWomChartData, updateVeWomTransactions, updatePieChartData } from './actions'
import { createReducer } from '@reduxjs/toolkit'
import { VeWomChartDayData, VeWomPieChart, VeWomTransaction } from 'types'
import { SupportedNetwork } from 'constants/networks'
import { VeWomField } from 'data/vewom/overview'

export interface VeWomData {
  veWomData: VeWomField
}

export type VeWomState = {
  [networkId in SupportedNetwork]: {
    // timestamp for last updated fetch
    readonly lastUpdated: number | undefined
    // overview data
    readonly data: VeWomData | undefined
    // Daily data of mint and burn
    readonly chartData: VeWomChartDayData[] | undefined
    // Top holders of veWOM
    readonly pieChartData: VeWomPieChart[] | undefined
    readonly transactions: VeWomTransaction[] | undefined
  }
}

function createDefaultInitialState() {
  return {
    data: undefined,
    chartData: undefined,
    pieChartData: undefined,
    transactions: undefined,
    lastUpdated: undefined,
  }
}

export const initialState: VeWomState = {
  [SupportedNetwork.BNB]: createDefaultInitialState(),
  [SupportedNetwork.ARBITRUM]: createDefaultInitialState(),
  [SupportedNetwork.ETHEREUM]: createDefaultInitialState(),
  [SupportedNetwork.POLYGON]: createDefaultInitialState(),
  [SupportedNetwork.AVALANCHE]: createDefaultInitialState(),
  [SupportedNetwork.BASE]: createDefaultInitialState(),
  [SupportedNetwork.OPTIMISM]: createDefaultInitialState(),
  [SupportedNetwork.SCROLL]: createDefaultInitialState(),
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVeWomData, (state, { payload: { data, networkId } }) => {
      state[networkId].data = data
      // mark when last updated
      state[networkId].lastUpdated = currentTimestamp()
    })
    .addCase(updateVeWomChartData, (state, { payload: { chartData, networkId } }) => {
      state[networkId].chartData = chartData
    })
    .addCase(updatePieChartData, (state, { payload: { pieChartData, networkId } }) => {
      state[networkId].pieChartData = pieChartData
    })
    .addCase(updateVeWomTransactions, (state, { payload: { transactions, networkId } }) => {
      state[networkId].transactions = transactions
    })
)
